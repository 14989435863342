export enum CourtSystemParams {
  CourtSystemId = 'courtSystemId',
  CourthouseId = 'courthouseId',
  CourtroomId = 'courtroomId',
  TurnaroundId = 'turnaroundId',
  YearMonth = 'yearMonth',
  Forbidden = 'forbidden',
}

export enum CourtSystemPaths {
  Admin = 'settings',
  AudioOrders = 'audio-orders',
  AcceptUseConditions = 'accept-use-conditions',
  Cases = 'cases',
  Departments = 'departments',
  Forbidden = 'forbidden',
  JoinCourtroom = 'join-detail',
  Locations = 'locations',
  MfaRequired = 'mfa-required',
  Order = 'order',
  Search = 'search',
  TranscriptOrders = 'transcript-orders',
  RealTimeHearings = 'real-time-hearings',
  RealTimeOrders = 'real-time-orders',
  RecordingPlayback = 'recording-playback',
  Recordings = 'recordings',
  Reports = 'reports',
}
