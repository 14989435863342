import { Timeframe, TimeframeWithLocalTimes } from '@ftr/contracts/api/shared'
import { RecordingSegment, RecordingWithSegments } from '@ftr/contracts/read'
import { Shortcut, ShortcutMap } from '@ftr/contracts/type/desktop'
import { LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { RemoteData } from '@ftr/foundation'
import { OnRecordState } from '@ftr/stt-search'
import { ZoneId } from '@js-joda/core'
import { PlaybackContext } from '../../types'
export enum PlaybackType {
  Recording = 'Recording',
  AudioOrder = 'AudioOrder',
  RealTimeOrder = 'RealTimeOrder',
  SharedRecording = 'SharedRecording',
  Hearing = 'Hearing',
  Job = 'Job',
}

export interface TranscriptExportData {
  location: string
}

export type RealTimeSecondaryContentType =
  | 'Video'
  | 'Search'
  | 'ShareRecording'
  | 'SharedWith'
  | 'ExportRecording'
  | 'KeyboardShortcuts'
  | 'RecordingTimeline'
  | 'CourtroomCalendar'
  | 'MultichannelControls'

export type RealTimeSecondaryContentDisplayCondition =
  | 'DesktopOnly' // Only show on desktop screen sizes
  | 'AlwaysVisible' // This content should always be visible

// playback split view modes
export type VideoViewMode =
  | 'splitView'
  | 'videoView'
  | 'sttView'
  | 'videoHidden'
  | 'fullscreenVideo'
  | 'pictureInPicture'
  | undefined

// Adjust to be an object and update state
export interface RealTimeSecondaryContent {
  type: RealTimeSecondaryContentType
  displayCondition: RealTimeSecondaryContentDisplayCondition
  fullWidth: boolean
}

export interface PlaybackStateModel {
  recordingId: Uuid | undefined
  courtSystemId: Uuid | undefined
  recordingName: string | undefined
  sttHasAtLeastOneUtterance: boolean
  exportData: TranscriptExportData | undefined
  isPlaybackPage: boolean
  /**
   * The timezone for this recording, handles stream and trm recordings,
   * is based on courthouse, falling back to court system
   */
  timezone: ZoneId | undefined
  courtRecording: RemoteData<RecordingWithSegments> | undefined
  onRecordState: OnRecordState
  stoppingSttForPreviousSession: boolean
  playbackType: PlaybackType | undefined
  canOrderTranscript: boolean
  canShareAudioOrder: boolean
  showingAudioOrderShareModal: boolean
  showingFootPedalSettingsModal: boolean
  showingPlayerShortcutConfigurationModal: boolean
  showingRecordingDiagnosticsModal: boolean
  showingShareRecordingModal: boolean
  showingSharedWithModal: boolean
  showingDownloadBundleModal: boolean
  availableTimeframes: TimeframeWithLocalTimes[] | undefined
  sealedTimeframes: Timeframe[] | undefined
  onRecordTimeframes: Timeframe[] | undefined
  /**
   * Warning: This is the unfiltered recording segments, availableTimeframes is what the user has access to
   */
  recordingSegments: RecordingSegment[] | undefined
  /**
   * Retroactive sealing: toggle for users who have the required permissions, sealing toolbar open
   */
  sealingMode: boolean
  /**
   * Retroactive sealing: valid time range value derived from the sealing toolbar inputs
   */
  sealingTimeRange: LocalTimeRange | undefined
  /**
   * Live sealing: toggle for live sealing, RealTime recordings only
   */
  liveSealing: boolean
  /**
   * Url for placing transcript orders externally if it is configured in the court system
   */
  externalTranscriptOrderUrl: string | undefined
  audioChannelCount: number | undefined
  hasMultiChannel: boolean
  showingChannels: boolean
  showingSideMenu: boolean
  showingVideo: boolean
  showingDebugInfo: boolean
  hasRealTimeVideo: boolean
  realTimeSecondaryContent: RealTimeSecondaryContent | undefined
  /**
   * Playback context, common between orders and recordings
   */
  playbackContext: PlaybackContext | undefined
  canAccessSealedContent: boolean | undefined
  canAccessOffTheRecordContent: boolean | undefined
  /**
   * PlaybackType.Recording, PlaybackType.Job:
   *   - autoscript must be enabled for TRM recordings
   *   - user must have ReadStt or TranscriberReadStt permission for recording
   *
   * PlaybackType.SharedRecording:
   *   - autoscript must be enabled for TRM recordings
   *   - STT must be included in shared recording
   *
   * PlaybackType.AudioOrder:
   *   - STT must be included in audio order
   *   - always false for shared audio orders
   *
   * PlaybackType.RealTimeOrder:
   *   - always true
   */
  canReadStt: boolean | undefined
  /**
   * PlaybackType.Recording, PlaybackType.Job:
   *   - must be TRM recording
   *   - user must have ReadLogSheets or TranscriberReadLogSheets permission for recording
   *
   * PlaybackType.SharedRecording:
   *   - must be TRM recording
   *   - log sheets must be included in shared recording
   *
   * PlaybackType.AudioOrder, PlaybackType.RealTimeOrder:
   *   - always false
   */
  canReadLogSheets: boolean | undefined
  /**
   * PlaybackType.Recording, PlaybackType.Job:
   *   - must be TRM recording
   *   - user must have DownloadBundles permission for court
   *   - user must have access to any sealed segments
   *
   * PlaybackType.SharedRecording:
   *   - must be TRM recording
   *   - download must be included in shared recording
   *
   * PlaybackType.AudioOrder, PlaybackType.RealTimeOrder:
   *   - always false
   */
  canDownloadBundle: boolean | undefined

  /**
   * Controls the visibility of the STT Jump to Playback/Live buttons on tablet and mobile viewport.
   *
   * Only relevant for RealTimePlayback pages
   */
  contentJumpButtonsVisible: boolean

  /**
   * Contains the users current shortcut to accelerator map, and any shortcuts that failed to bind
   */
  shortcuts: ShortcutMap
  failedShortcuts: Shortcut[]
  isAudioSegmentLive: boolean
  canManagePastSessions: boolean | undefined

  /**
   * Controls the playback page video view mode.
   *
   * Only relevant for RealTimePlayback pages
   */
  videoViewMode: VideoViewMode | undefined
  showVideoViewModeMenu: boolean
  videoFullscreenView: boolean
}
