import { Injectable } from '@angular/core'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { CourtSystem, CourtSystemWithMetadata } from '@ftr/contracts/api/court-system'
import { Feature } from '@ftr/contracts/api/feature'
import { AccessibleEntityMap } from '@ftr/contracts/api/permissions'
import {
  DEFAULT_PEDAL_AUTOMATIC_REWIND,
  DEFAULT_PEDAL_ORDER,
  DEFAULT_SHOW_MFA_SETUP,
  UserLocation,
  UserSettings,
  UserWithGlobalAdministratorRole,
} from '@ftr/contracts/api/user'
import { UserGroup, UserGroupPermission, UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { AudioPlaybackFeature } from '@ftr/contracts/read/audio-playback-feature'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, LocalStorageService, MapUtils, RemoteData, unwrapData } from '@ftr/foundation'
import {
  AudioPlaybackFeatureService,
  CoreConfigurationService,
  EnabledCourtSystemFeaturesService,
  GetCourtSystemService,
} from '@ftr/ui-court-system'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { Observable, combineLatest, map, of, tap } from 'rxjs'
import { UserPermissionService, UserService, UserSettingsService } from '../services'
import { ADMIN_PAGE_PERMISSIONS } from '../util'
import {
  GetCurrentCourtSystemAction,
  GetCurrentCourtSystemAudioPlaybackFeature,
  GetCurrentCourtSystemConfigurationAction,
  GetCurrentUserAction,
  GetCurrentUserGroupsAction,
  GetCurrentUserLocationAction,
  GetCurrentUserSettingsAction,
  GetEnabledFeaturesForCourtSystem,
  GetInitialCurrentUserAction,
  GetInitialCurrentUserGroupsAction,
  GetInitialUserSettingsAction,
  LogoutAction,
  ResetCurrentCourtSystemAudioPlaybackFeature,
  SetCurrentCourtSystemConfiguration,
  SetCurrentUserSettingsAction,
  SetMfaRecoveryCodeModalOpenStatus,
  SetMfaSetupModal,
} from './user.actions'
import { MfaSetupModalAction, MfaSetupModalType, UserStateModel } from './user.model'

export function defaultUserState(): UserStateModel {
  return {
    user: undefined,
    userGroups: undefined,
    userLocation: undefined,
    userSettings: {
      automaticRewind: DEFAULT_PEDAL_AUTOMATIC_REWIND,
      pedalOrder: DEFAULT_PEDAL_ORDER,
      showMfaSetup: DEFAULT_SHOW_MFA_SETUP,
      customShortcutsMac: [],
      customShortcutsWindows: [],
    },
    courtSystems: undefined,
    courtSystem: undefined,
    courtSystemConfiguration: undefined,
    audioPlaybackFeature: undefined,
    enabledFeatures: undefined,
    mfa: {
      mfaRecoveryModalOpen: false,
      mfaSetupModalType: MfaSetupModalType.Closed,
      mfaSetupModalAction: MfaSetupModalAction.NotNow,
    },
  }
}

export const CURRENT_COURT_SYSTEM_KEY = 'current-court-system'

let initialUserSettingsLoaded = false

const adminPermissionSet = [...ADMIN_PAGE_PERMISSIONS, UserGroupPermissionId.AccessReports]

/**
 * We are using ngxs v4 options that prevent UserState from being injected as the first parameter
 * in a @Selector when other selectors are supplied.
 */
@State<UserStateModel>({
  name: 'userState',
  defaults: defaultUserState(),
})
@Injectable()
export class UserState {
  constructor(
    private readonly userService: UserService,
    private readonly getCourtSystemService: GetCourtSystemService,
    private readonly localStorageService: LocalStorageService,
    private readonly coreConfigurationService: CoreConfigurationService,
    private readonly userPermissionService: UserPermissionService,
    private readonly userSettingsService: UserSettingsService,
    private readonly enabledCourtSystemFeaturesService: EnabledCourtSystemFeaturesService,
    private readonly audioPlaybackFeatureService: AudioPlaybackFeatureService,
  ) {}

  /**
   * Equivalent to @Selector([UserState])
   */
  @Selector()
  static user(state: UserStateModel): RemoteData<UserWithGlobalAdministratorRole> {
    return state.user || RemoteData.notAsked()
  }

  /**
   * A selector that uses another selector must come after it in the file, otherwise it won't be defined.
   * They seem to evaluate in order.
   */
  @Selector([UserState.user])
  static isLoggedIn(user: ReturnType<typeof UserState.user>): boolean {
    return user.isSuccess() && !!user._data
  }

  @Selector([UserState.user])
  static isGlobalAdmin(user: ReturnType<typeof UserState.user>): boolean {
    return user.isSuccess() && user._data.isGlobalAdministrator
  }

  @Selector([UserState.currentCourtSystem])
  static isInternalUser(currentCourtSystem: ReturnType<typeof UserState.currentCourtSystem>): boolean {
    return currentCourtSystem !== undefined
  }

  @Selector([UserState.user])
  static currentUserEmail(user: ReturnType<typeof UserState.user>): RemoteData<string> {
    return user.map(x => x.email) || RemoteData.notAsked()
  }

  @Selector()
  static currentUserGroups(state: UserStateModel): RemoteData<UserGroup[]> {
    return state.userGroups || RemoteData.notAsked()
  }

  @Selector()
  static currentUserLocation(state: UserStateModel): UserLocation | undefined {
    return state.userLocation
  }

  @Selector()
  static currentUserSettings(state: UserStateModel): UserSettings {
    return state.userSettings
  }

  @Selector()
  static courtSystems(state: UserStateModel): RemoteData<CourtSystem[]> {
    return state.courtSystems || RemoteData.notAsked()
  }

  /**
   * The 'current court system' is only for the header and footer links at this point.
   * In order to move toward a proper multi-tenanted system similar to Stripe, we would need to change many more things.
   */
  @Selector()
  static currentCourtSystem(state: UserStateModel): CourtSystem | undefined {
    return state.courtSystem
  }

  @Selector([UserState.courtSystems, UserState.currentCourtSystem])
  static currentCourtSystemRemoteData(
    courtSystems: ReturnType<typeof UserState.courtSystems>,
    currentCourtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): RemoteData<CourtSystem | undefined> {
    return courtSystems.map(_ => currentCourtSystem)
  }

  @Selector([UserState.courtSystems])
  static courtSystemById(
    courtSystems: ReturnType<typeof UserState.courtSystems>,
  ): (courtSystemId: Uuid) => CourtSystem | undefined {
    const fn = (courtSystemId: Uuid): CourtSystem | undefined => {
      if (courtSystems.isSuccess()) {
        return courtSystems._data.find(c => c.id === courtSystemId)
      }
      return undefined
    }
    return fn
  }

  @Selector()
  static currentCourtSystemConfiguration(state: UserStateModel): RemoteData<PrivateConfiguration> {
    return state.courtSystemConfiguration || RemoteData.notAsked()
  }

  @Selector()
  static currentCourtSystemAudioPlaybackFeature(state: UserStateModel): RemoteData<AudioPlaybackFeature | undefined> {
    return state.audioPlaybackFeature || RemoteData.notAsked()
  }

  @Selector()
  static currentCourtSystemEnabledFeatures(state: UserStateModel): RemoteData<Feature[] | undefined> {
    return state.enabledFeatures || RemoteData.notAsked()
  }

  /**
   * This selector does not consider restricted permissions
   */
  @Selector([UserState.currentUserGroups, UserState.currentCourtSystem])
  static hasPermissionInCourtSystem(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
    courtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): (permission: UserGroupPermissionId, courtSystemId?: Uuid) => boolean | undefined {
    const fn = (permission: UserGroupPermissionId, courtSystemId?: Uuid): boolean | undefined => {
      const permissions = permissionsInCourtSystem(userGroups, courtSystem, courtSystemId)

      /**
       * Wait for required data fields to be defined before performing the
       * permission check, otherwise we will get a false negative if we do
       * the `some` check when the expected dataset is incomplete.
       */
      if (permissions === undefined) {
        return undefined
      }

      return permissions.some(p => p.id === permission)
    }

    return fn
  }

  @Selector([UserState.currentUserGroups])
  static hasPermissionInAnyCourtSystem(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
  ): (permission: UserGroupPermissionId) => boolean {
    return (permission: UserGroupPermissionId): boolean => {
      const enabledPermissions = permissionsInAllCourtSystems(userGroups)

      if (enabledPermissions === undefined || enabledPermissions.length < 1) {
        return false
      }

      return enabledPermissions.some(p => p.id === permission)
    }
  }

  @Selector([UserState.currentUserGroups, UserState.currentCourtSystem])
  static restrictedEntitiesInCourtSystemForPermission(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
    courtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): (
    permission: UserGroupPermissionId,
    configuration: PrivateConfiguration,
    courtSystemId?: Uuid,
  ) => AccessibleEntityMap | undefined {
    const fn = (
      permission: UserGroupPermissionId,
      configuration: PrivateConfiguration,
      courtSystemId?: Uuid,
    ): AccessibleEntityMap => {
      const { restrictedByLocation, restrictedByDepartment } = configuration
      const permissions = permissionsInCourtSystem(userGroups, courtSystem, courtSystemId) || []
      const permissionModels = permissions
        .filter(p => p.id === permission)
        .filter(this.isRestrictedByPermission(restrictedByLocation, restrictedByDepartment))
        .flatMap(p => p.models || [])

      return MapUtils.buildGroupedSetMultimap(
        permissionModels.filter(m => m.restrictedModelId !== null),
        model => model.restrictedModelType,
        model => model.restrictedModelId!,
      )
    }
    return fn
  }

  private static isRestrictedByPermission(restrictedByLocation: boolean, restrictedByDepartment: boolean) {
    return (permission: UserGroupPermission) =>
      (restrictedByLocation && permission.models?.some(m => m.restrictedModelType === 'location')) ||
      (restrictedByDepartment && permission.models?.some(m => m.restrictedModelType === 'department'))
  }

  @Selector([UserState.currentUserGroups, UserState.currentCourtSystem])
  static getEnabledPermissions(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
    courtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): (courtSystemId?: Uuid) => UserGroupPermission[] {
    return (courtSystemId?: Uuid): UserGroupPermission[] => {
      return permissionsInCourtSystem(userGroups, courtSystem, courtSystemId) || []
    }
  }

  @Selector([UserState.currentUserGroups, UserState.currentCourtSystem])
  static hasAnyPermissionInCourtSystem(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
    courtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): (permissions: UserGroupPermissionId[], courtSystemId?: Uuid) => boolean {
    const fn = (permissions: UserGroupPermissionId[], courtSystemId?: Uuid): boolean => {
      const enabledPermissions = permissionsInCourtSystem(userGroups, courtSystem, courtSystemId)

      if (enabledPermissions === undefined) {
        return false
      }

      return enabledPermissions.some(p => permissions.includes(p.id))
    }

    return fn
  }

  @Selector([UserState.currentUserGroups, UserState.currentCourtSystem])
  static hasOnlyPermissionInCourtSystem(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
    courtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): (permission: UserGroupPermissionId, courtSystemId?: Uuid) => boolean {
    const fn = (permission: UserGroupPermissionId, courtSystemId?: Uuid): boolean => {
      const enabledPermissions = permissionsInCourtSystem(userGroups, courtSystem, courtSystemId)

      if (enabledPermissions === undefined || enabledPermissions.length !== 1) {
        return false
      }

      const [enabledPermission] = enabledPermissions

      return enabledPermission.id === permission
    }

    return fn
  }

  @Selector([UserState.currentUserGroups])
  static hasOnlyPermissionInAllCourtSystems(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
  ): (permissionId: UserGroupPermissionId) => boolean {
    const fn = (permissionId: UserGroupPermissionId): boolean => {
      const enabledPermissions = permissionsInAllCourtSystems(userGroups)
      return !enabledPermissions?.some(permission => permission.id !== permissionId)
    }
    return fn
  }

  /**
   * Whether this user has any of the permissions considered 'administrative'.
   * @param userGroups
   * @param courtSystem
   */
  @Selector([UserState.currentUserGroups, UserState.currentCourtSystem])
  static hasAdminPermissionsInCourtSystem(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
    courtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): boolean {
    const enabledPermissions = permissionsInCourtSystem(userGroups, courtSystem)

    if (enabledPermissions === undefined) {
      return false
    }

    return adminPermissionSet.some(perm => {
      return enabledPermissions.some(p => p.id === perm)
    })
  }

  @Selector([UserState.currentUserGroups, UserState.currentCourtSystem])
  static permissionsInCurrentCourtSystem(
    userGroups: ReturnType<typeof UserState.currentUserGroups>,
    courtSystem: ReturnType<typeof UserState.currentCourtSystem>,
  ): UserGroupPermissionId[] | undefined {
    return permissionsInCourtSystem(userGroups, courtSystem)?.map(p => p.id)
  }

  @Selector()
  static showingMfaRecoveryCodeModal(state: UserStateModel): boolean {
    return state.mfa.mfaRecoveryModalOpen
  }

  @Selector()
  static mfaSetupModalType(state: UserStateModel): MfaSetupModalType {
    return state.mfa.mfaSetupModalType
  }

  @Selector()
  static mfaSetupModalAction(state: UserStateModel): MfaSetupModalAction {
    return state.mfa.mfaSetupModalAction
  }

  @Action(GetCurrentUserAction)
  getCurrentUser({ getState, dispatch }: StateContext<UserStateModel>): void {
    if (getState().user === undefined) {
      dispatch(new GetInitialCurrentUserAction())
    }
  }

  @Action(GetInitialCurrentUserAction)
  getInitialCurrentUser({ patchState }: StateContext<UserStateModel>): ApiResult<UserWithGlobalAdministratorRole> {
    return this.userService.getUserDetails().pipe(
      tap(user => {
        patchState({ user })
      }),
    )
  }

  @Action(GetCurrentCourtSystemAction)
  getCurrentCourtSystem(
    { getState, patchState, dispatch }: StateContext<UserStateModel>,
    { courtSystem, courtSystems }: GetCurrentCourtSystemAction,
  ): void {
    // Getting current court system from the state/local storage
    let currentCourtSystem: CourtSystem | undefined = courtSystem
    if (!currentCourtSystem) {
      currentCourtSystem = getState().courtSystem
        ? getState().courtSystem
        : this.localStorageService.get<CourtSystem | undefined>(CURRENT_COURT_SYSTEM_KEY)
    }

    currentCourtSystem = this.fixCourtSystemName(courtSystems, currentCourtSystem)

    // If for some reason the current court system is not on the list of user's court systems,
    // pick the first one off the list
    if (!currentCourtSystem || !courtSystems.find(c => !!currentCourtSystem && c.id === currentCourtSystem.id)) {
      currentCourtSystem = courtSystems[0]
    }

    // This will avoid errors as some users will not have a 'current' court system.
    if (currentCourtSystem) {
      dispatch(new GetCurrentCourtSystemConfigurationAction(currentCourtSystem.id))
      dispatch(new GetEnabledFeaturesForCourtSystem(currentCourtSystem.id))
    }

    this.localStorageService.set(CURRENT_COURT_SYSTEM_KEY, currentCourtSystem)
    patchState({ courtSystem: currentCourtSystem })
  }

  @Action(LogoutAction)
  logout({ setState }: StateContext<UserStateModel>): void {
    setState(defaultUserState())
  }

  @Action(GetInitialCurrentUserGroupsAction)
  getInitialCurrentUserGroups({
    patchState,
    dispatch,
  }: StateContext<UserStateModel>): Observable<[RemoteData<UserGroup[]>, RemoteData<CourtSystemWithMetadata[]>]> {
    return combineLatest([
      this.userPermissionService.fetchCurrentUserGroups(),
      this.getCourtSystemService
        .getCourtSystemsForUser({ adminOnly: false, withFeatures: true })
        .pipe(
          map(courtSystemsRemoteData =>
            courtSystemsRemoteData.map(xs => xs.sort((a, b) => a.name.localeCompare(b.name))),
          ),
        ),
    ]).pipe(
      tap(([userGroups, courtSystems]) => {
        let patchedState: Partial<UserStateModel> = { userGroups, courtSystems }

        if (userGroups.isSuccess() && userGroups.data && courtSystems.isSuccess() && courtSystems.data) {
          dispatch(new GetCurrentCourtSystemAction(courtSystems._data))
        } else if (userGroups.isFailure() || courtSystems.isFailure()) {
          patchedState = { ...patchedState, user: defaultUserState().user }
        }

        patchState(patchedState)
      }),
    )
  }

  @Action(GetCurrentUserGroupsAction)
  getCurrentUserGroups({ getState, dispatch }: StateContext<UserStateModel>): void {
    if (getState().userGroups === undefined) {
      dispatch(new GetInitialCurrentUserGroupsAction())
    }
  }

  @Action(GetCurrentUserLocationAction)
  getCurrentUserLocation({ getState, patchState }: StateContext<UserStateModel>): Observable<UserLocation | undefined> {
    if (getState().userLocation !== undefined) {
      return of(getState().userLocation)
    }
    return this.userService.getLocation().pipe(
      unwrapData(),
      tap(userLocation => {
        if (userLocation) {
          patchState({ userLocation })
        }
      }),
    )
  }

  @Action(GetCurrentUserSettingsAction)
  getUserSettings({ dispatch }: StateContext<UserStateModel>): Observable<void> | void {
    if (!initialUserSettingsLoaded) {
      initialUserSettingsLoaded = true
      return dispatch(new GetInitialUserSettingsAction())
    }
  }

  @Action(SetCurrentUserSettingsAction)
  setCurrentUserSettingsAction(
    { patchState }: StateContext<UserStateModel>,
    { userSettings }: SetCurrentUserSettingsAction,
  ): void {
    patchState({ userSettings })
  }

  @Action(GetInitialUserSettingsAction)
  getInitialUserSettingsAction({ patchState }: StateContext<UserStateModel>): Observable<UserSettings | undefined> {
    return this.userSettingsService.fetchCurrentUserSettings().pipe(
      unwrapData(),
      tap(userSettings => {
        if (userSettings) {
          patchState({ userSettings })
        }
      }),
    )
  }

  @Action(GetCurrentCourtSystemConfigurationAction)
  getCurrentCourtSystemConfiguration(
    { patchState }: StateContext<UserStateModel>,
    { courtSystemId }: GetCurrentCourtSystemConfigurationAction,
  ): ApiResult<PrivateConfiguration> {
    return this.coreConfigurationService.getExtendedConfigByCourtSystem(courtSystemId).pipe(
      tap(courtSystemConfiguration => {
        patchState({ courtSystemConfiguration })
      }),
    )
  }

  @Action(GetCurrentCourtSystemAudioPlaybackFeature)
  getAudioPlaybackFeature(
    { getState, patchState }: StateContext<UserStateModel>,
    { courtSystemId }: GetCurrentCourtSystemAudioPlaybackFeature,
  ): ApiResult<AudioPlaybackFeature> | undefined {
    return getState().audioPlaybackFeature === undefined
      ? this.audioPlaybackFeatureService
          .getByCourtSystem(courtSystemId)
          .pipe(tap(feature => patchState({ audioPlaybackFeature: feature })))
      : undefined
  }

  @Action(ResetCurrentCourtSystemAudioPlaybackFeature)
  resetAudioPlaybackFeature({ patchState }: StateContext<UserStateModel>): void {
    patchState({ audioPlaybackFeature: undefined })
  }

  @Action(GetEnabledFeaturesForCourtSystem)
  getCurrentCourtSystemFeatures(
    { getState, patchState }: StateContext<UserStateModel>,
    { courtSystemId }: GetEnabledFeaturesForCourtSystem,
  ): ApiResult<Feature[]> {
    const stateEnabledFeatures = getState().enabledFeatures?.data
    const inThisCourtSystem =
      stateEnabledFeatures && stateEnabledFeatures.length && stateEnabledFeatures[0].courtSystemId === courtSystemId
    return inThisCourtSystem
      ? ApiResult.success(stateEnabledFeatures)
      : this.enabledCourtSystemFeaturesService
          .findEnabledFeaturesForCourtSystem(courtSystemId)
          .pipe(tap(enabledFeatures => patchState({ enabledFeatures })))
  }

  @Action(SetMfaRecoveryCodeModalOpenStatus)
  setMfaRecoveryCodesModalOpenStatus(
    { getState, patchState }: StateContext<UserStateModel>,
    { modalOpen: mfaRecoveryModalOpen }: SetMfaRecoveryCodeModalOpenStatus,
  ): void {
    patchState({ mfa: { ...getState().mfa, mfaRecoveryModalOpen } })
  }

  @Action(SetMfaSetupModal)
  setMfaSetupModal(
    { getState, patchState }: StateContext<UserStateModel>,
    { mfaSetupModalType, mfaSetupModalAction }: SetMfaSetupModal,
  ): void {
    patchState({ mfa: { ...getState().mfa, mfaSetupModalType, mfaSetupModalAction } })
  }

  @Action(SetCurrentCourtSystemConfiguration)
  setCurrentCourtSystemConfiguration(
    { getState, patchState }: StateContext<UserStateModel>,
    { configuration }: SetCurrentCourtSystemConfiguration,
  ): void {
    const { courtSystemConfiguration } = getState()
    const oldConfiguration = courtSystemConfiguration?.data
    if (oldConfiguration && oldConfiguration?.courtSystemId === configuration.courtSystemId) {
      const newConfiguration = {
        ...oldConfiguration,
        ...configuration,
      }
      patchState({ courtSystemConfiguration: RemoteData.success(newConfiguration) })
    }
  }

  // HACK: Basically this is done to change the header dropdown text on page load.
  // For a full explanation. See AR-83
  private fixCourtSystemName(
    courtSystemsInState: CourtSystem[],
    currentCourtSystem: CourtSystem | undefined,
  ): CourtSystem | undefined {
    const currentCourtSystemInState = courtSystemsInState.find(x => x.id === currentCourtSystem?.id)
    if (
      currentCourtSystem &&
      currentCourtSystemInState?.name &&
      currentCourtSystemInState?.name !== currentCourtSystem.name
    ) {
      currentCourtSystem = { ...currentCourtSystem, name: currentCourtSystemInState.name }
    }
    return currentCourtSystem
  }
}

function permissionsInCourtSystem(
  userGroups: RemoteData<UserGroup[]>,
  courtSystem: CourtSystem | undefined,
  courtSystemId: Uuid | undefined = undefined,
): UserGroupPermission[] | undefined {
  if (!userGroups.isSuccess()) {
    return undefined
  }

  if (!courtSystemId && courtSystem) {
    courtSystemId = courtSystem.id
  }

  if (!userGroups._data || !courtSystemId) {
    return undefined
  }

  return getEnabledPermissionsFromCourtSystemUserGroups(courtSystemId, userGroups._data)
}

function getEnabledPermissionsFromCourtSystemUserGroups(
  courtSystemId: Uuid,
  userGroups: UserGroup[],
): UserGroupPermission[] {
  return userGroups
    .filter(g => g.courtSystemId === courtSystemId)
    .flatMap(g => {
      return g.permissions.filter(p => p.isEnabled === true)
    })
}

function permissionsInAllCourtSystems(userGroups: RemoteData<UserGroup[]>): UserGroupPermission[] | undefined {
  if (!userGroups.isSuccess() || !userGroups._data) {
    return undefined
  }

  return userGroups._data.flatMap(g => {
    return g.permissions.filter(p => p.isEnabled === true)
  })
}
