import { Injectable } from '@angular/core'
import { Hearing } from '@ftr/annotations-contracts'
import { AnnotationsApiClientFactory } from '@ftr/api-annotations'
import { NotFoundApiError } from '@ftr/api-shared'
import { Uuid } from '@ftr/contracts/type/shared/Uuid'
import { RemoteData } from '@ftr/foundation'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store'
import { patch, ɵPatchSpec } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom, of } from 'rxjs'
import {
  DeleteHearingAction,
  FetchHearingAction,
  SetHearingRemoteAction,
  UpdateHearingAction,
} from './hearings.actions'
import {
  HearingDeletedEvent,
  HearingDeletionFailedEvent,
  HearingUpdatedEvent,
  HearingUpdateFailedEvent,
} from './hearings.events'
import { HearingInstanceStateModel, HearingsStateModel } from './hearings.model'

export function defaultHearingsState(): HearingsStateModel {
  return {
    hearingInstanceState: {},
  }
}

export function defaultHearingInstanceState(): HearingInstanceStateModel {
  return {
    hearingRemote: RemoteData.notAsked(),
    expires: Date.now(),
  }
}

@State<HearingsStateModel>({
  name: 'hearingsState',
  defaults: defaultHearingsState(),
})
@Injectable()
export class HearingsState {
  constructor(
    private readonly regionCache: CourtSystemRegionCache,
    private readonly annotationsApiFactory: AnnotationsApiClientFactory,
  ) {}

  @Selector()
  static allHearingInstanceStates(state: HearingsStateModel): HearingsStateModel['hearingInstanceState'] {
    return state.hearingInstanceState
  }

  static readonly hearingInstanceState = memoize(
    (
      hearingId: Uuid,
    ): ((sourceStates: ReturnType<typeof HearingsState.allHearingInstanceStates>) => HearingInstanceStateModel) => {
      return createSelector(
        [HearingsState.allHearingInstanceStates],
        (sourceStates: ReturnType<typeof HearingsState.allHearingInstanceStates>) =>
          sourceStates[hearingId] ?? defaultHearingInstanceState(),
      )
    },
  )

  static readonly neighbouringHearings = memoize(
    (
      hearingId: Uuid,
    ): ((sourceStates: ReturnType<typeof HearingsState.allHearingInstanceStates>) => RemoteData<{
      next: Hearing | null
      previous: Hearing | null
    }>) => {
      return createSelector(
        [HearingsState.allHearingInstanceStates],
        (sourceStates: ReturnType<typeof HearingsState.allHearingInstanceStates>) => {
          const sourceState = sourceStates[hearingId]
          if (!sourceState) {
            return RemoteData.notAsked()
          }
          return RemoteData.combine({
            previous: sourceState.hearingRemote.flatMap(hearing =>
              hearing.previousHearingForCase
                ? (sourceStates[hearing.previousHearingForCase] ?? defaultHearingInstanceState()).hearingRemote
                : RemoteData.success(null),
            ),
            next: sourceState.hearingRemote.flatMap(hearing =>
              hearing.nextHearingForCase
                ? (sourceStates[hearing.nextHearingForCase] ?? defaultHearingInstanceState()).hearingRemote
                : RemoteData.success(null),
            ),
          })
        },
      )
    },
  )

  @Action(FetchHearingAction)
  async fetchHearing(
    { getState, dispatch }: StateContext<HearingsStateModel>,
    { courtSystemId, hearingId }: FetchHearingAction,
  ): Promise<void> {
    const originalState = getState().hearingInstanceState[hearingId] ?? defaultHearingInstanceState()

    // Don't re-fetch the hearing if there is already a result or request in flight
    // Clear the state first if you want to re-fetch
    const existingFetch = originalState.hearingRemote

    const expired = Date.now() > originalState.expires

    if (existingFetch.isLoading() || (existingFetch.isSuccess() && !expired)) {
      // Do nothing if it is already loading or has a successful result which is not expired
      return
    }

    dispatch(new SetHearingRemoteAction(hearingId, RemoteData.loading()))

    const apiClient = this.annotationsApiFactory.build(
      (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(courtSystemId)))).region,
    )
    const result = await apiClient.hearing.getHearing({ params: { hearingId } }).catch(err => ({ fetchError: err }))
    if ('fetchError' in result) {
      dispatch(new SetHearingRemoteAction(hearingId, RemoteData.failure(result.fetchError as Error)))
    } else if (result.status !== 200) {
      dispatch(
        new SetHearingRemoteAction(
          hearingId,
          RemoteData.failure(result.status === 404 ? new NotFoundApiError() : new Error(result.body.error)),
        ),
      )
    } else {
      dispatch(new SetHearingRemoteAction(hearingId, RemoteData.success(result.body)))
    }
  }

  @Action(UpdateHearingAction)
  async updateHearing(
    { getState, dispatch }: StateContext<HearingsStateModel>,
    { hearingId, hearingUpdate }: UpdateHearingAction,
  ): Promise<void> {
    const originalState = getState().hearingInstanceState[hearingId] ?? defaultHearingInstanceState()
    if (!originalState.hearingRemote.isSuccess()) {
      dispatch(new HearingUpdateFailedEvent(hearingId))
      return
    }

    const groomedHearingUpdate = {
      ...hearingUpdate,
      title: hearingUpdate.title?.trim() || null,
    }

    const apiClient = this.annotationsApiFactory.build(
      (
        await firstValueFrom(
          this.regionCache.getOrFetchCourtSystem(of(originalState.hearingRemote._data.courtSystemId)),
        )
      ).region,
    )
    const result = await apiClient.hearing
      .updateHearing({
        body: groomedHearingUpdate,
        params: { hearingId },
      })
      .catch(err => ({ fetchError: err }))
    if ('fetchError' in result) {
      dispatch(new HearingUpdateFailedEvent(hearingId))
      return
    }

    if (result.status !== 200) {
      dispatch(new HearingUpdateFailedEvent(hearingId))
      return
    }

    dispatch([
      new SetHearingRemoteAction(hearingId, RemoteData.success(result.body)),
      new HearingUpdatedEvent(
        hearingId,
        result.body.caseId,
        result.body.hearingSections.length !== originalState.hearingRemote._data.hearingSections.length,
        originalState.hearingRemote._data,
        result.body,
      ),
    ])
  }

  @Action(DeleteHearingAction)
  async deleteHearing(
    { getState, dispatch }: StateContext<HearingsStateModel>,
    { hearingId }: DeleteHearingAction,
  ): Promise<void> {
    const originalState = getState().hearingInstanceState[hearingId] ?? defaultHearingInstanceState()

    if (!originalState.hearingRemote.isSuccess()) {
      dispatch(new HearingDeletionFailedEvent(hearingId))
      return
    }

    const apiClient = this.annotationsApiFactory.build(
      (
        await firstValueFrom(
          this.regionCache.getOrFetchCourtSystem(of(originalState.hearingRemote._data.courtSystemId)),
        )
      ).region,
    )
    const result = await apiClient.hearing
      .deleteHearing({
        params: { hearingId },
      })
      .catch(err => ({ fetchError: err }))
    if ('fetchError' in result) {
      dispatch(new HearingDeletionFailedEvent(hearingId))
      return
    }

    if (result.status !== 204) {
      dispatch(new HearingDeletionFailedEvent(hearingId))
      return
    }

    const caseDeleted =
      (await apiClient.case.getCase({ params: { caseId: originalState.hearingRemote._data.caseId } })).status === 404

    dispatch([
      new SetHearingRemoteAction(hearingId, RemoteData.notAsked()),
      new HearingDeletedEvent(hearingId, caseDeleted),
    ])
  }

  @Action(SetHearingRemoteAction)
  async setCase(
    { setState }: StateContext<HearingsStateModel>,
    { hearingId, hearingRemote }: SetHearingRemoteAction,
  ): Promise<void> {
    patchHearingInstanceState(setState, hearingId, {
      hearingRemote,
      expires: Date.now() + 5000,
    })
  }
}

function patchHearingInstanceState(
  setState: StateContext<HearingsStateModel>['setState'],
  hearingId: Uuid,
  update: ɵPatchSpec<HearingInstanceStateModel>,
): void {
  setState(
    patch<HearingsStateModel>({
      hearingInstanceState: patch({
        [hearingId]: patch(update),
      }),
    }),
  )
}
