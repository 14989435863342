<ftr-animated-remote
  [remote]="viewModel"
  [loadingTemplate]="loading"
  [failureTemplate]="failure"
  [emptyTemplate]="success"
  [successTemplate]="success"
  class="order-review"
>
  <ng-template #loading>
    <ftr-line-loader message="Loading the review order step"></ftr-line-loader>
  </ng-template>

  <ng-template #failure>
    <ftr-error-retry
      text="Failed to load the review order step. Please try again or contact support@fortherecord.com for support."
      [icon]="false"
      (retry)="refresh$.next()"
    ></ftr-error-retry>
  </ng-template>

  <ng-template #success let-data>
    <h2>Order Summary</h2>
    <p>Please review the following details prior to placing your order.</p>
    <hr />
    <ftr-court-system-summary [summary]="data.courtSystemSummary"> </ftr-court-system-summary>

    <ftr-order-summary
      [quotes]="data.quotes"
      [canViewOrderPrice]="data.canViewOrderPrice"
      [additionalData]="data.additionalData"
      [showEdit]="true"
      [costWaiverRequested]="costWaiverRequested"
    ></ftr-order-summary>

    @if (data.additionalData.transcriptProvider?.isEnabled) {
      <div class="order-review__transcript-provider">
        <ftr-transcript-provider
          [courtSystemName]="data.courtSystemName"
          [transcriptProvider]="data.additionalData.transcriptProvider"
        >
        </ftr-transcript-provider>
      </div>
    }

    <div class="order-payment-container">
      <ftr-untyped-form
        class="order-payment__form form-group"
        name="order-payment"
        [submission]="submission"
        [formGroup]="formGroup"
        (formSubmit)="goToNextStep()"
        [submitLabel]="isResubmitting ? 'Resubmit Order' : 'Place Order'"
        [submittingLabel]="isResubmitting ? 'Resubmitting Order' : 'Placing Order'"
        [formTemplate]="form"
      >
        <ng-template #form let-highlightErrors="highlightErrors">
          <h2>{{ getOrderAndPaymentSectionTitle() }}</h2>

          @if (isCostWaiverSupported()) {
            <ftr-checkbox
              [label]="costWaiverConfiguration?.optionText"
              [name]="orderReviewFieldNames.CostWaiverRequested"
              [control]="asFormControl(formGroup.controls[orderReviewFieldNames.CostWaiverRequested])"
            >
            </ftr-checkbox>
          }

          @if (isBillToBillingGroupWithUnwaivableFees()) {
            <p>Cost waivers are not supported for billing group speech-to-text orders.</p>
          }

          @if (costWaiverRequested) {
            <div class="order-review__cost-waiver">
              @if (costWaiverConfiguration?.documentIsOptional) {
                <h3 ftrOptionalLabel>Proof of Eligibility</h3>
              } @else {
                <h3>Proof of Eligibility</h3>
              }
              @if (hasUnwaivableFees) {
                <ftr-notification [type]="notificationType.Info">
                  <p>Cost waiver only applies to the audio portion of the order.</p>
                </ftr-notification>
              }
              <div class="order-review__cost-waiver-description" markdown>
                {{ costWaiverConfiguration?.documentText }}
              </div>
              <ftr-file-upload
                [control]="formGroup.controls[orderReviewFieldNames.CostWaiverAttachments]"
                [highlightError]="highlightErrors"
                [sizeLimit]="uploadSizeLimit"
                [maxFiles]="1"
                [fileUploadService]="orderAttachmentFileUploadService"
                [fileUploadProps]="{ courtSystemId: courtSystemId }"
                dropOverlayText="Drop files here to attach to your order"
                (uploading)="setUploadState($event)"
                [acceptableFileExtensions]="validOrderAttachmentExtensions"
              >
              </ftr-file-upload>
            </div>
          }

          @if (isPaymentRequiredToPlaceOrder()) {
            @if (data.billingGroupMessage) {
              <p markdown>
                {{ data.billingGroupMessage }}
              </p>
            } @else {
              @if (isEstimatedPrice) {
                <p>
                  When this order is placed, a hold of the estimate will be taken. Upon completion of the order, you
                  will be liable for payment of the final price, which may be higher or lower than the held estimate.
                </p>
              }
              @if (isTotalPrice) {
                <p>When you place this order, you are authorizing payment of the displayed total price.</p>
              }
            }
            <ftr-secure-notice actionLabel="Transaction"></ftr-secure-notice>
            <ftr-payment-fields
              [formGroup]="formGroup"
              [highlightErrors]="highlightErrors"
              [paymentApiKey]="paymentApiKey"
              [savedCards]="data.savedCards || []"
            >
            </ftr-payment-fields>
            <div class="resubmit-order__payment-notification">
              @if (isResubmitting && orderPrices?.fundsReleased) {
                <ftr-notification [type]="notificationType.Info" [markdown]="false">
                  The previous estimate of
                  <strong>
                    <ftr-money [money]="orderPrices!.fundsReleased" [absoluteValue]="true"></ftr-money>
                  </strong>
                  that was being held for your order has been released back to you. The new estimated price shown above
                  will be held when you click the Resubmit Order button.
                </ftr-notification>
              }
            </div>
          } @else {
            @if (data.billingGroupMessage) {
              <p markdown>
                {{ data.billingGroupMessage }}
              </p>
            } @else if (data.isInternalClient) {
              <p>
                As an internal client of this court system your payment is not required until the
                {{ isMultiDayOrder ? 'transcripts are' : 'transcript is' }} ready for download.
              </p>
            }
          }
        </ng-template>
      </ftr-untyped-form>
    </div>
  </ng-template>
</ftr-animated-remote>
