/* eslint-disable max-classes-per-file */
import { RealTimeRecordingWithSegments } from '@ftr/api-regional'
import { AudioSegmentPlaybackData } from '@ftr/contracts/api/audio-segment'
import { Timeframe, TimeframeWithLocalTimes } from '@ftr/contracts/api/shared'
import { TranscriptOrderingFeature } from '@ftr/contracts/api/transcript-ordering-feature'
import { Shortcut, ShortcutMap } from '@ftr/contracts/type/desktop'
import { DataStore, LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { ZoneId } from '@js-joda/core'
import { PlaybackContext } from '../../types'
import { PlaybackType, RealTimeSecondaryContent, VideoViewMode } from './playback.model'
export class SetPlaybackInfo {
  static readonly type = '[Playback] Set Playback Export Information'

  constructor(
    public recordingId: Uuid | undefined,
    public courtSystemId: Uuid | undefined,
    public recordingName: string | undefined,
    public location: string,
    public playbackType: PlaybackType | undefined,
    public canOrderTranscript: boolean,
    public canShareAudioOrder: boolean,
    public audioChannelCount: number,
    public hasMultiChannel: boolean,
    public showingChannels = false,
    public playbackContext?: PlaybackContext,
    public canReadStt = false,
    public canReadLogSheets = false,
    public canDownloadBundle = false,
    public canAccessSealedContent = false,
    public canAccessOffTheRecordContent = false,
    public canManagePastSessions = false,
  ) {}
}

export class SetRecordingTimezone {
  static readonly type = '[Playback] Set Recording Timezone'

  constructor(public recordingTimezone: ZoneId) {}
}

export class SetSttHasAtLeastOneUtterance {
  static readonly type = '[Playback] Set STT Has At Least One Utterance'

  constructor(public sttHasAtLeastOneUtterance: boolean) {}
}

export class SetIsPlaybackPage {
  static readonly type = '[Playback] Set On Playback Page'

  constructor(public isPlaybackPage: boolean) {}
}

export class ResetPlaybackState {
  static readonly type = '[Playback] Reset Playback State'
}

export class ResetCourtRecording {
  static readonly type = '[Playback] Reset Recording'
}

export class FetchCourtRecording {
  static readonly type = '[Playback] Fetch Recording'

  constructor(
    public recordingId: Uuid,
    public courtSystemId: Uuid,
    public dataStore = DataStore.Core,
    public force = false,
  ) {}
}

export class SetShowingRecordingDiagnosticsModal {
  static readonly type = '[Playback] Showing recording vault diagnostics modal'

  constructor(public showingRecordingDiagnosticsModal = false) {}
}

export class SetShowingAudioOrderShareModal {
  static readonly type = '[Playback] Showing audio order share modal'

  constructor(public showingAudioOrderShareModal = false) {}
}

export class SetShowingFootPedalSettingsModal {
  static readonly type = '[Playback] Showing foot pedal settings modal'

  constructor(public showingFootPedalSettingsModal = false) {}
}

export class SetShortcuts {
  static readonly type = '[Playback] Setting shortcuts and any failed to bind shortcuts'

  constructor(
    public shortcuts: ShortcutMap,
    public failedShortcuts: Shortcut[],
  ) {}
}

export class SetShowingPlayerShortcutConfigurationModal {
  static readonly type = '[Playback] Showing playback shortcut configuration modal'

  constructor(public showingPlayerShortcutConfigurationModal = false) {}
}

export class SetShowingShareRecordingModal {
  static readonly type = '[Playback] Showing share recording modal'

  constructor(public showingShareRecordingModal = false) {}
}

export class SetShowingSharedWithModal {
  static readonly type = '[Playback] Showing shared with modal'

  constructor(public showingSharedWithModal = false) {}
}

export class SetShowingDownloadBundleModal {
  static readonly type = '[Playback] Showing download bundle modal'

  constructor(public showingDownloadBundleModal = false) {}
}

export class UpdateSealedTimeframes {
  static readonly type = '[Playback] Update sealed timeframes'

  constructor(public sealedTimeframes: Timeframe[]) {}
}

export class SetSealingMode {
  static readonly type = '[Playback] Set Court Recording sealing mode'

  constructor(public sealingMode: boolean) {}
}

export class SetShowingChannels {
  static readonly type = '[Playback] Set showing channels'

  constructor(public showingChannels: boolean) {}
}

export class ToggleShowingChannels {
  static readonly type = '[Playback] Toggle showing channels'
}

export class SetSealingTimeRange {
  static readonly type = '[Playback] Set Court Recording to be sealed time range'

  constructor(public timeRange?: LocalTimeRange) {}
}

export class SetExternalTranscriptOrderingUrl {
  static readonly type = '[Playback] Set External Transcript Ordering Url if configured'

  constructor(
    public courtSystemId: Uuid,
    public hearingDate: string,
    public feature: TranscriptOrderingFeature | undefined,
    public courthouseName: string | undefined,
    public courtroomName: string | undefined,
    public departmentName: string | undefined,
  ) {}
}

export class ToggleShowingSideMenu {
  static readonly type = '[Playback] Toggle Showing Side Menu'
}

export class SetShowingSideMenu {
  static readonly type = '[Playback] Set Showing Side Menu'

  constructor(public showingSideMenu: boolean) {}
}

export class SetHasRealTimeVideo {
  static readonly type = '[Playback] Set has RealTime video'

  constructor(public hasRealTimeVideo: boolean) {}
}

export class SetShowingVideo {
  static readonly type = '[Playback] Set showing video'

  constructor(public showingVideo: boolean) {}
}

export class SetVideoViewMode {
  static readonly type = '[Playback] Set video view mode'

  constructor(public videoViewMode: VideoViewMode | undefined) {}
}

export class SetShowVideoViewModeMenu {
  static readonly type = '[Playback] Set show video view mode menu'

  constructor(public showVideoViewModeMenu: boolean) {}
}

export class SetVideoFullscreenView {
  static readonly type = '[Playback] Set video fullscreen view'

  constructor(public videoFullscreenView: boolean) {}
}

export class SetRealTimeSecondaryContent {
  static readonly type = '[Playback] Set RealTime secondary content'

  constructor(public content: RealTimeSecondaryContent | undefined) {}
}

export class UpdateLiveAudioSegmentPlaybackData {
  static readonly type = '[Playback] Update live audio segment'

  constructor(
    public audioSegment: AudioSegmentPlaybackData | undefined,
    public realTimeSttEnabled: boolean,
  ) {}
}

export class UpdateLiveRecordingInfo {
  static readonly type = '[Playback] Update live recording'

  constructor(
    public recording: RealTimeRecordingWithSegments | undefined,
    public realTimeSttEnabled: boolean,
  ) {}
}

export class ReevaluateOnRecordState {
  static readonly type = '[Playback] Reevaluate on record state'

  constructor(public readonly realTimeSttEnabled: boolean) {}
}

export class ScheduleStoppingSttForSessionTimeout {
  static readonly type = '[Playback] Schedule stopping session timeout'

  constructor(
    /**
     * The current on the record timeframes when the session first transitioned to stopping
     */
    public onTheRecordTimeframesWhenSessionStopped: Timeframe[],
  ) {}
}

export class MarkSttStoppedForSession {
  static readonly type = '[Playback] Mark STT stopping for session as stopped'
}

export class SetShowingDebugInfo {
  static readonly type = '[Playback] Set Showing Debug Info'

  constructor(public showingDebugInfo: boolean) {}
}

export class SetContentJumpButtonsVisibilityAction {
  static readonly type = '[Playback] Set Content Jump Buttons Visibility'

  constructor(public contentJumpButtonsVisible: boolean) {}
}

export class ToggleContentJumpButtonsVisibilityAction {
  static readonly type = '[Playback] Toggle Content Jump Buttons Visibility'
}

export class UpdateAvailableTimeframes {
  static readonly type = '[Playback] Update Available Timeframes'

  constructor(public availableTimeframes: TimeframeWithLocalTimes[]) {}
}
