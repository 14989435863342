import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ToastContainerDirective, ToastrModule } from 'ngx-toastr'
import { ButtonComponent } from '../button'
import { IconComponent } from '../icon'
import { ToastContainerComponent } from './toast-container.component'
import { ToastComponent } from './toast.component'

@NgModule({
  declarations: [ToastComponent, ToastContainerComponent],
  imports: [CommonModule, IconComponent, ToastrModule.forRoot(), ButtonComponent, ToastContainerDirective],
  exports: [ToastComponent, ToastContainerComponent, ToastrModule],
})
export class ToastModule {}
