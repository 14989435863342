import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { PageDetails, PagedResult } from '@ftr/contracts/api/core'
import {
  AddUsersToCourtSystemGroupsBody,
  CourtSystem,
  CourtSystemUser,
  CourtSystemWithMetadata,
  CreateCourtSystemBody,
  PatchCourtSystemBody,
  SetCourtUserWithGroupsBody,
} from '@ftr/contracts/api/court-system'
import { ListUsersForCourtSystemQuery, User } from '@ftr/contracts/api/user'
import { UserGetForCourtSystemParams } from '@ftr/contracts/read'
import { sortAlphabeticallyByProps } from '@ftr/contracts/shared/ArrayUtils'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign, mapData } from '@ftr/foundation'
import { GetCourtSystemService } from './get-court-system.service'

const USER_PAGE_SIZE = 100

@Injectable()
export class CourtSystemService {
  private readonly courtSystemApiClient: ApiClient
  private readonly userApiClient: ApiClient

  constructor(
    apiClientFactory: ApiClientFactory,
    private getCourtSystemService: GetCourtSystemService,
  ) {
    this.courtSystemApiClient = apiClientFactory.build('/court-system')
    this.userApiClient = apiClientFactory.build('/user')
  }

  getAllCourtSystems(): ApiResult<CourtSystemWithMetadata[]> {
    return this.courtSystemApiClient.get<CourtSystemWithMetadata[]>({
      path: '/all',
    })
  }

  getCourtSystem<T extends CourtSystem>(courtSystemId: string): ApiResult<T> {
    return this.getCourtSystemService.getCourtSystem(courtSystemId) as ApiResult<T>
  }

  getUser(courtSystemId: string, userId: string): ApiResult<CourtSystemUser> {
    return this.userApiClient.get({
      path: '/forCourtSystem',
      params: serializeHttpParams(createAndAssign(UserGetForCourtSystemParams, { courtSystemId, userId })),
    })
  }

  getUserById(courtSystemId: string, userId: string): ApiResult<User> {
    return this.userApiClient.get({
      path: '/getById',
      params: serializeHttpParams(createAndAssign(UserGetForCourtSystemParams, { courtSystemId, userId })),
    })
  }

  getUsers(
    courtSystemId: string,
    page: number,
    searchTerms?: string,
    userGroupId?: Uuid,
  ): ApiResult<PagedResult<CourtSystemUser>> {
    return this.courtSystemApiClient
      .get<PagedResult<CourtSystemUser>>({
        path: `/${courtSystemId}/users`,
        params: serializeHttpParams(
          createAndAssign(ListUsersForCourtSystemQuery, {
            searchTerms,
            userGroupId,
            page: new PageDetails(page, USER_PAGE_SIZE),
          }),
        ),
      })
      .pipe(mapData(data => mapPagedUsers(data)))
  }

  updateUserGroups(courtSystemId: string, body: SetCourtUserWithGroupsBody): ApiResult<CourtSystemUser> {
    return this.courtSystemApiClient.put({
      path: `/${courtSystemId}/user`,
      body,
    })
  }

  addUsersToGroups(courtSystemId: Uuid, body: AddUsersToCourtSystemGroupsBody): ApiResult<CourtSystemUser[]> {
    return this.courtSystemApiClient.put({
      path: `/${courtSystemId}/add-users`,
      body,
    })
  }

  create(createCourtSystemBody: CreateCourtSystemBody): ApiResult<CourtSystem> {
    return this.courtSystemApiClient.post({ body: createCourtSystemBody })
  }

  patch(courtSystemId: Uuid, body: PatchCourtSystemBody): ApiResult<CourtSystem> {
    return this.courtSystemApiClient.patch({
      path: `/${courtSystemId}/`,
      body,
    })
  }
}

function mapPagedUsers(users: PagedResult<CourtSystemUser>): PagedResult<CourtSystemUser> {
  return {
    meta: users.meta,
    items: users.items.map(user => {
      return {
        ...user,
        groups: user.groups.sort(sortAlphabeticallyByProps('name')),
      }
    }),
  }
}
