import { Injectable } from '@angular/core'
import {
  LiveSttResult,
  LiveSttResultUpdated,
  LiveSttSessionEmissionStatusUpdated,
} from '@ftr/contracts/regional-api/live-stt'
import {
  JoinAudioSegmentLiveStreamRoomRequest,
  JoinAudioSegmentLiveStreamSealedContentRoomRequest,
  JoinRecordingLiveStreamRoomRequest,
  JoinRecordingLiveStreamSealedContentRoomRequest,
} from '@ftr/contracts/socket'
import { Region } from '@ftr/contracts/type/court/Region'
import { Message, Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { assertUnreachable } from '@ftr/foundation'
import { ClassConstructor } from '@ftr/serialization'
import { SocketService } from '@ftr/ui-user'
import { Observable, OperatorFunction, buffer, concatMap, filter, interval, map } from 'rxjs'

export type JoinLiveStreamRoomRequest =
  | JoinRecordingLiveStreamRoomRequest
  | JoinRecordingLiveStreamSealedContentRoomRequest
  | JoinAudioSegmentLiveStreamRoomRequest
  | JoinAudioSegmentLiveStreamSealedContentRoomRequest

const LIVE_STT_BUFFER_TIME_MS = 500

@Injectable({
  providedIn: 'root',
})
export class RealTimeLiveSttService {
  constructor(private readonly socketService: SocketService) {}

  observeLiveSttResults(
    joinLiveStreamRoomRequest: JoinLiveStreamRoomRequest,
    onErrorJoiningRoom?: () => void,
  ): Observable<LiveSttResult> {
    return this.listenToLiveSttUpdates(joinLiveStreamRoomRequest, onErrorJoiningRoom)
  }

  observeLiveSttSessionEmissionStatusUpdates(
    joinLiveStreamRoomRequest: JoinLiveStreamRoomRequest,
    onErrorJoiningRoom?: () => void,
  ): Observable<LiveSttSessionEmissionStatusUpdated> {
    return this.listenToLiveSttSessionEmissionStatusUpdates(joinLiveStreamRoomRequest, onErrorJoiningRoom)
  }

  private listenToLiveSttUpdates(
    joinLiveStreamRoomRequest: JoinLiveStreamRoomRequest,
    onErrorJoiningRoom?: () => void,
  ): Observable<LiveSttResult> {
    return this.listenForMessage(joinLiveStreamRoomRequest, LiveSttResultUpdated, onErrorJoiningRoom).pipe(
      stabilizeLiveStt(),
    )
  }

  private listenToLiveSttSessionEmissionStatusUpdates(
    joinLiveStreamRoomRequest: JoinLiveStreamRoomRequest,
    onErrorJoiningRoom?: () => void,
  ): Observable<LiveSttSessionEmissionStatusUpdated> {
    return this.listenForMessage(joinLiveStreamRoomRequest, LiveSttSessionEmissionStatusUpdated, onErrorJoiningRoom)
  }

  listenForMessage<T extends Message>(
    joinLiveStreamRoomRequest: JoinLiveStreamRoomRequest,
    constructor: ClassConstructor<T>,
    onErrorJoiningRoom?: () => void,
  ): Observable<T> {
    return this.socketService.onMessage(joinLiveStreamRoomRequest, constructor, onErrorJoiningRoom)
  }
}

export function buildJoinLiveStreamRoomRequest(
  playbackKey: RealTimePlaybackKey,
  courtSystemId: Uuid,
  courtSystemRegion: Region,
  canAccessSealedContent: boolean,
  authToken: string,
): JoinLiveStreamRoomRequest | undefined {
  const sharedJoinRequestDetails = { courtSystemId, region: courtSystemRegion, auth: authToken }

  switch (playbackKey.type) {
    case 'audio-segment':
      return playbackKey.recordingId
        ? {
            ...sharedJoinRequestDetails,
            type: canAccessSealedContent ? 'audio-segment-live-stream-sealed-content' : 'audio-segment-live-stream',
            recordingId: playbackKey.recordingId,
            audioSegmentId: playbackKey.audioSegmentId,
          }
        : undefined
    // ST-3333: Live content not currently supported for hearing playback
    case 'hearing':
      return undefined
    case 'recording':
      return {
        ...sharedJoinRequestDetails,
        type: canAccessSealedContent ? 'recording-live-stream-sealed-content' : 'recording-live-stream',
        recordingId: playbackKey.recordingId,
      }
    default:
      assertUnreachable(playbackKey)
  }
}

export function stabilizeLiveStt(): OperatorFunction<LiveSttResultUpdated, LiveSttResult> {
  return source =>
    source.pipe(
      filter(u => !!u.result.endTime),
      map(update => update.result),
      buffer(interval(LIVE_STT_BUFFER_TIME_MS)),
      concatMap(x => x),
    )
}
