import { AsyncPipe, NgClass } from '@angular/common'
import {
  Component,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
  output,
} from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { Icon, IconComponent } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'

let nextId = 0

export const FORM_INPUT_TOKEN = new InjectionToken<Partial<FormInputComponent>>('FORM_INPUT_TOKEN')

/**
 * A wrapper for an input element, including rendering of elements essential to validation display.
 */

@Component({
  selector: 'ftr-form-input',
  templateUrl: './form-input.component.html',
  standalone: true,
  imports: [FormsModule, NgClass, AsyncPipe, ValidationErrorHintDirective, ReactiveFormsModule, IconComponent],
  providers: [{ provide: FORM_INPUT_TOKEN, useExisting: forwardRef(() => FormInputComponent) }],
})
export class FormInputComponent implements OnInit {
  @Input() id = `ftr-input-${nextId++}`
  @Input() type = 'text'
  @Input() name?: string
  @Input() label: string
  @Input() placeholder: string
  @Input() prefix?: string
  @Input() iconPrefix?: Icon

  @Input() pattern?: string
  @Input() spellcheck?: boolean
  @Input() tabIndex?: number
  /**
   * Label to be used to display validation. If not set, this defaults to <code>label</code>.
   */
  @Input() validationLabel?: string
  @Input() autocomplete?: string | 'on' | 'off'
  @Input() required = false
  @Input() ngFormControl: UntypedFormControl | FormControl
  @Input() submitAttempted: boolean
  @Input() highlightError: Observable<boolean>
  @Input() notrack = false
  // Optionally disable default validation display
  @Input() hideValidationErrors = false

  /**
   * Hide the label that is shown above the input and show the placeholder instead.
   * @type {boolean}
   */
  @Input() hideLabel = false

  /**
   * The maximum number of characters that can be entered into this field.
   */
  @Input() maxLength: number | undefined

  @Input() displayMode: 'DEFAULT' | 'FLAT' = 'DEFAULT'

  // An optional text appended to the label, e.g. `label - labelSuffix`
  @Input() labelSuffix?: string

  @Output() onChange: EventEmitter<void> = new EventEmitter<void>()
  @Output() onKeyDown = new EventEmitter<KeyboardEvent>()
  @Output() onKeyUp = new EventEmitter<KeyboardEvent>()
  focus = output<FocusEvent>()

  @ViewChild('input', { static: true }) input: ElementRef

  hasValue = false

  ngOnInit(): void {
    this.updateHasValue(this.ngFormControl.value)
    this.ngFormControl.valueChanges.subscribe(value => {
      this.onChange.emit()
      this.updateHasValue(value)
    })
  }

  updateHasValue(value: string | number): void {
    this.hasValue = typeof value === 'number' || !!value
  }

  focusInput(): void {
    this.input.nativeElement.focus()
  }

  handleKeyDown($event: KeyboardEvent): void {
    this.onKeyDown.emit($event)
  }

  handleKeyUp($event: KeyboardEvent): void {
    this.onKeyUp.emit($event)
  }
}
