import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CustomerOrder, PaymentWaivedReason } from '@ftr/contracts/api/order'
import { Quote } from '@ftr/contracts/api/quote'
import { SavedCard } from '@ftr/contracts/read/order-account'
import { CostWaiverConfiguration, ORDER_DETAILS_EXTENSIONS } from '@ftr/contracts/type/core'
import { hasUnwaivableFees } from '@ftr/contracts/type/order/UnwaivableFees'
import { Uuid } from '@ftr/contracts/type/shared'
import { StepNavigation } from '@ftr/forms'
import { ApiResult, NotificationType, RemoteData, mapData, tapData, unwrapData } from '@ftr/foundation'
import { BillingGroupService } from '@ftr/ui-admin'
import { CourtSystemSummary, CourtSystemsState, PersonalizationConfiguration } from '@ftr/ui-court-system'
import { attachmentsRequiredValidator } from '@ftr/ui-files'
import { FetchSavedCardsAction, OrderAccountState, OrderPricesService, PricesViewModel } from '@ftr/ui-ordering'
import { Store } from '@ngxs/store'
import bytes from 'bytes'
import { BehaviorSubject, combineLatest, map, startWith, switchMap } from 'rxjs'
import { OrderAttachmentFileUploadService } from '~app/features/order-attachments/order-attachment-upload/order-attachment-file-upload.service'
import { QuoteService } from '~app/features/order-form-quote/quote.service'
import { OrderFormGroups } from '~app/features/order-form-shared/order-form-builder.service'
import { setupOrderCardFields } from '~app/features/order-form-shared/order-form-utils'
import { MissingPaymentDetails } from '~app/features/order-form-shared/steps/order-review/error/MissingPaymentDetails'
import { AdditionalData } from '~app/features/order-form-shared/summary/OrderSummaryData'
import { PaymentData, PaymentFieldsComponent } from '~app/features/order-payment/payment-fields.component'
import { OrderFormService } from '../../order-form.service'

export interface OrderReviewComponentViewModel {
  savedCards: SavedCard[]
  canViewOrderPrice: boolean
  billingGroupMessage?: string
  isInternalClient: boolean
  quotes: Quote[]
  additionalData: AdditionalData
  courtSystemName: string | undefined
  courtSystemSummary: CourtSystemSummary | undefined
}

export enum OrderReviewFieldNames {
  CostWaiverAttachments = 'costWaiverAttachments',
  CostWaiverRequested = 'costWaiverRequested',
  PaymentNewCard = 'newCard',
  PaymentSaveCard = 'saveCreditOrDebitCard',
  PaymentSavedCardId = 'cardId',
}

@Component({
  selector: 'ftr-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderReviewComponent extends StepNavigation implements OnInit {
  @Output() onStepReady = new EventEmitter<void>(true)
  @ViewChild(PaymentFieldsComponent)
  paymentFieldsComponent: PaymentFieldsComponent
  uploadSizeLimit = bytes.parse('50MB')!

  formGroup: UntypedFormGroup
  paymentApiKey: string
  isResubmitting: boolean
  orderPrices: PricesViewModel | undefined
  isTotalPrice: boolean
  isEstimatedPrice: boolean
  isPaymentRequired: boolean
  isMultiDayOrder: boolean

  courtSystemId: Uuid
  customerOrder: CustomerOrder | undefined
  costWaiverConfiguration: CostWaiverConfiguration | undefined
  costWaiverRequested = false
  hasUnwaivableFees = false
  isUploading: boolean = false

  readonly orderReviewFieldNames = OrderReviewFieldNames
  readonly notificationType = NotificationType

  viewModel: ApiResult<OrderReviewComponentViewModel>

  refresh$ = new BehaviorSubject<void>(undefined)

  readonly validOrderAttachmentExtensions = ORDER_DETAILS_EXTENSIONS

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly orderFormService: OrderFormService,
    private readonly orderPricesService: OrderPricesService,
    private readonly billingGroupService: BillingGroupService,
    readonly orderAttachmentFileUploadService: OrderAttachmentFileUploadService,
    private readonly quoteService: QuoteService,
    private readonly store: Store,
  ) {
    super()
  }

  ngOnInit(): void {
    this.isEstimatedPrice = this.orderFormService.isEstimatedPrice()
    this.isTotalPrice = this.orderFormService.isTotalPrice()
    this.formGroup = this.orderFormService.getFormForStep(OrderFormGroups.Review)
    this.paymentApiKey = this.orderFormService.getPaymentApiKey()
    this.isResubmitting = this.orderFormService.isResubmitting()
    this.isPaymentRequired = this.orderFormService.isPaymentRequired()
    this.courtSystemId = this.activatedRoute.snapshot.queryParams.courtSystemId
    this.customerOrder = this.orderFormService.getCustomerOrder()
    this.isMultiDayOrder = this.orderFormService.isMultiDayOrder()

    this.configurePriceModel()
    this.viewModel = this.getViewModel()
  }

  private getViewModel(): ApiResult<OrderReviewComponentViewModel> {
    const summaryData = this.orderFormService.createSummaryData()
    this.store.dispatch(new FetchSavedCardsAction())

    return this.refresh$.pipe(
      switchMap(() =>
        ApiResult.combine([
          this.store.select(OrderAccountState.savedCards),
          ApiResult.from(
            combineLatest(
              this.orderFormService
                .getFormGroupsForOrdersToBePlaced()
                .map(formGroup => this.orderFormService.mapToCreateQuoteBody(formGroup))
                .map(quoteBody => this.quoteService.createQuoteObservable(quoteBody)),
            ),
          ),
          this.orderFormService.getAdditionalReviewPageData(this.courtSystemId),
          ApiResult.from(
            this.store.select(CourtSystemsState.courtSystemPersonalization).pipe(
              map(f => f(this.courtSystemId)),
              unwrapData(),
            ),
          ),
        ]),
      ),
      tapData(([, quotes]) => {
        this.hasUnwaivableFees = hasUnwaivableFees(quotes)
      }),
      mapData(([savedCards, quotes, additionalData, personalizationConfiguration]) =>
        this.createViewModel(
          savedCards,
          quotes,
          additionalData,
          summaryData.caseDetails?.courtSystemName,
          personalizationConfiguration,
        ),
      ),
      startWith(RemoteData.loading()),
      tapData(viewModel => {
        this.setupForm(viewModel.savedCards)
        this.onStepReady.emit()
      }),
    )
  }

  setupForm(savedCards: SavedCard[]): void {
    this.costWaiverConfiguration = this.orderFormService.getCostWaiverConfiguration()

    const costWaiverRequested = this.formGroup.controls[OrderReviewFieldNames.CostWaiverRequested]
    this.costWaiverRequested = costWaiverRequested.value
    this.setupCardFields(savedCards)

    const costWaiverAttachments = this.formGroup.controls[OrderReviewFieldNames.CostWaiverAttachments]
    const paymentNewCard = this.formGroup.controls[OrderReviewFieldNames.PaymentNewCard]
    const paymentSavedCardId = this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId]

    costWaiverRequested.valueChanges.subscribe(checked => {
      this.costWaiverRequested = checked

      if (this.costWaiverRequested) {
        // If the cost waiver document is optional, do not require the attachments
        this.costWaiverConfiguration?.documentIsOptional
          ? costWaiverAttachments.clearValidators()
          : costWaiverAttachments.setValidators(attachmentsRequiredValidator)

        costWaiverAttachments.updateValueAndValidity()

        paymentNewCard.clearValidators()
        paymentNewCard.updateValueAndValidity()

        paymentSavedCardId.clearValidators()
        paymentSavedCardId.updateValueAndValidity()
      } else {
        costWaiverAttachments.clearValidators()
        costWaiverAttachments.updateValueAndValidity()

        this.setupCardFields(savedCards)
      }
    })
  }

  // Much of this is duplicated from payment-fields component.
  // Not sure of a way around it due to expression changed errors and not wanting to affect other consumers.
  setupCardFields(savedCards: SavedCard[]): void {
    if (this.costWaiverRequested || !this.isPaymentRequired) {
      return
    }

    setupOrderCardFields(this.formGroup, savedCards)
  }

  async goToNextStep(): Promise<void> {
    const paymentData = this.isPaymentRequiredToPlaceOrder() ? await this.paymentFieldsComponent.getData() : undefined

    /*
      If the customer has somehow ignored the payment fields (for example, if they clicked the 'I have a cost waiver'
      checkbox, included STT earlier, but didn't read the warning that the STT payment on the order is not waivable,
      then this short-circuit displays a final validation warning rather than submitting to Beast which will return
      a generic 500 error due to an invalid submission.

      FIXME: should Beast return 422 unprocessable entity here? The issue is with the submitted data.
     */
    if (this.isPaymentRequiredToPlaceOrder() && !OrderReviewComponent.hasValidPaymentData(paymentData)) {
      return this.submissionSubject.next(RemoteData.failure(new MissingPaymentDetails()))
    }

    const submission = this.orderFormService.createSubmission(paymentData)

    this.orderFormService.validateAndSubmitOrder(submission).subscribe(
      response => {
        this.submissionSubject.next(RemoteData.success(response))

        this.router.navigate(['../', 'confirmation'], {
          queryParams: {
            orderId: this.orderFormService.getOrderId(),
            orderGroupId: this.orderFormService.getOrderGroupId(),
            resubmission: this.isResubmitting,
          },
          relativeTo: this.activatedRoute,
        })
      },
      err => {
        this.submissionSubject.next(RemoteData.failure(err))
      },
    )
  }

  setUploadState(uploading: boolean): void {
    this.isUploading = uploading
  }

  getOrderAndPaymentSectionTitle(): string {
    if (this.isPaymentRequired) {
      return 'Payment Details'
    }

    return this.isResubmitting ? 'Resubmit Order' : 'Place Order'
  }

  isPaymentRequiredToPlaceOrder(): boolean {
    return this.orderFormService.isPaymentRequiredToPlaceOrder(this.hasUnwaivableFees, this.costWaiverRequested)
  }

  isCostWaiverSupported(): boolean {
    return this.orderFormService.isCostWaiverSupported(
      this.customerOrder,
      this.isBillToBillingGroupWithUnwaivableFees(),
    )
  }

  isBillToBillingGroupWithUnwaivableFees(): boolean {
    return this.orderFormService.isBillToBillingGroupWithUnwaivableFees(this.hasUnwaivableFees)
  }

  private createViewModel(
    savedCards: SavedCard[],
    quotes: Quote[],
    additionalData: AdditionalData,
    courtSystemName: string | undefined,
    personalizationConfiguration: PersonalizationConfiguration | undefined,
  ): OrderReviewComponentViewModel {
    const billingGroup = this.orderFormService.getBillingGroup()
    const paymentWaivedReason = this.orderFormService.getPaymentWaivedReason()
    const isInternalClient = this.orderFormService.isInternalClient()
    const canViewOrderPrice = this.orderFormService.canViewOrderPrices()

    const billingGroupMessage =
      billingGroup && paymentWaivedReason === PaymentWaivedReason.BilledToUserGroup
        ? this.billingGroupService.getBilledToUserGroupMessage(billingGroup, this.isMultiDayOrder)
        : billingGroup?.configuration.customPaymentMessage

    const courtSystemSummary = {
      name: courtSystemName,
      countryCode: personalizationConfiguration?.countryCode,
      stateCode: personalizationConfiguration?.stateCode,
      logoUrl: personalizationConfiguration?.logoUrl,
    }

    return {
      savedCards,
      billingGroupMessage,
      canViewOrderPrice,
      isInternalClient,
      quotes,
      additionalData,
      courtSystemName,
      courtSystemSummary,
    }
  }

  private configurePriceModel(): void {
    if (this.isResubmitting) {
      const customerOrder = this.orderFormService.getCustomerOrder()
      if (customerOrder) {
        this.orderPrices = this.orderPricesService.buildPricesViewModel(customerOrder)
        /**
         * There is no endpoint for fetching charge authorizations and this is
         * the last time we have access to funds released from the previous charge
         * before the new charge gets authorized so we use the order form service to pass
         * it to the confirmation page
         */
        this.orderFormService.setFundsReleased(this.orderPrices.fundsReleased)
      }
    }
  }

  private static hasValidPaymentData(paymentData?: PaymentData): boolean {
    return paymentData?.cardId !== undefined || paymentData?.paymentToken !== undefined
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
