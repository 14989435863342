/* eslint-disable max-classes-per-file */
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { UserSettings } from '@ftr/contracts/api/user'
import { Uuid } from '@ftr/contracts/type/shared'
import { MfaSetupModalAction, MfaSetupModalType } from './user.model'

export class GetCurrentUserAction {
  static readonly type = '[User] Get Current User'
}

export class GetInitialCurrentUserAction {
  static readonly type = '[User] Get Current User ignoring cache'
}

export class GetCurrentCourtSystemAction {
  static readonly type = '[User] Get Current Court System'
  constructor(
    public courtSystems: CourtSystem[],
    public courtSystem?: CourtSystem,
  ) {}
}

export class LogoutAction {
  static readonly type = '[User] Logout'
}

export class GetInitialCurrentUserGroupsAction {
  static readonly type = '[User] Get Current User Groups ignoring cache'
}

export class GetCurrentUserGroupsAction {
  static readonly type = '[User] Get Current User Groups'
}

export class GetCurrentUserLocationAction {
  static readonly type = '[User] Get Current User Location'
}

export class GetInitialUserSettingsAction {
  static readonly type = '[User] Get Initial User Settings ignoring cache'
}

export class GetCurrentUserSettingsAction {
  static readonly type = '[User] Get Current User Settings'
}

export class SetCurrentUserSettingsAction {
  static readonly type = '[Search] SetCurrentUserSettingsAction'

  constructor(public userSettings: UserSettings) {}
}

export class GetCurrentCourtSystemConfigurationAction {
  static readonly type = '[User] Get Current Court System Configuration'
  constructor(public courtSystemId: Uuid) {}
}

export class GetCurrentCourtSystemAudioPlaybackFeature {
  static readonly type = '[User] Get Current Court System Audio Playback Feature Config'

  constructor(public courtSystemId: Uuid) {}
}

export class ResetCurrentCourtSystemAudioPlaybackFeature {
  static readonly type = '[User] Reset Current Court System Audio Playback Feature Config'
}

export class GetEnabledFeaturesForCourtSystem {
  static readonly type = '[User] Get enabled features for the given court system'
  constructor(public courtSystemId: Uuid) {}
}

export class SetMfaRecoveryCodeModalOpenStatus {
  static readonly type = "[User] Set the visible status of the current user's MFA Recovery Codes modal"

  constructor(public modalOpen: boolean) {}
}

export class SetMfaSetupModal {
  static readonly type = '[User] Set mfa setup modal type and action'
  constructor(
    public mfaSetupModalType: MfaSetupModalType,
    public mfaSetupModalAction: MfaSetupModalAction = MfaSetupModalAction.NotNow,
  ) {}
}

export class SetCurrentCourtSystemConfiguration {
  static readonly type = '[User] Set Current Court System Configuration'
  constructor(public configuration: Pick<PrivateConfiguration, 'courtSystemId'> & Partial<PrivateConfiguration>) {}
}
