import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { PageDetails, PagedResult } from '@ftr/contracts/api/core'
import {
  CreateDepartment,
  Department,
  DepartmentOverviewRequest,
  DepartmentSummary,
  ListDepartmentsByCourtSystemParameters,
  ListDepartmentsByCourtSystemResponse,
  ListDepartmentsQuery,
  PostConfigureSupportingDocuments,
  UpdateDepartment,
} from '@ftr/contracts/api/department'
import { ListLocationsByCourtSystemRequest } from '@ftr/contracts/api/location'
import { DepartmentSupportingDocumentsConfiguration } from '@ftr/contracts/type/court'
import { LineItemType } from '@ftr/contracts/type/order'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'

const DEPARTMENT_LIST_SIZE = 100

/**
 * The number of active departments required to enable the departments feature
 */
export const MINIMUM_ACTIVE_DEPARTMENTS = 2

@Injectable()
export class DepartmentService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('/department')
  }

  create(createDepartment: CreateDepartment): ApiResult<Department> {
    const body = classToPlain(createDepartment)
    return this.apiClient.post({
      body,
    })
  }

  update(departmentId: Uuid, updateDepartment: UpdateDepartment): ApiResult<Department> {
    const body = classToPlain(updateDepartment)
    return this.apiClient.put({
      path: `${departmentId}`,
      body,
    })
  }

  list(
    courtSystemId: Uuid,
    isArchived: boolean | undefined,
    page: number = 1,
    pageSize = DEPARTMENT_LIST_SIZE,
  ): ApiResult<PagedResult<Department>> {
    return this.apiClient.get({
      params: serializeHttpParams(
        createAndAssign(ListDepartmentsQuery, {
          courtSystemId,
          isArchived,
          page: new PageDetails(page, pageSize),
        }),
      ),
    })
  }

  listByCourtSystem({
    courtSystemId,
  }: ListDepartmentsByCourtSystemParameters): ApiResult<ListDepartmentsByCourtSystemResponse> {
    return this.apiClient.get<ListDepartmentsByCourtSystemResponse>({
      path: '/list',
      params: serializeHttpParams(new ListLocationsByCourtSystemRequest(courtSystemId)),
    })
  }

  get(departmentId: Uuid): ApiResult<Department> {
    return this.apiClient.get({ path: departmentId })
  }

  getSupportingDocumentsConfiguration(
    departmentId: Uuid,
    lineItemType: LineItemType,
  ): ApiResult<DepartmentSupportingDocumentsConfiguration> {
    return this.apiClient.get({ path: `${departmentId}/supportingDocumentsConfiguration?lineItemType=${lineItemType}` })
  }

  configureSupportingDocuments(
    departmentId: Uuid,
    configureSupportingDocuments: PostConfigureSupportingDocuments,
  ): ApiResult<DepartmentSupportingDocumentsConfiguration> {
    const body = classToPlain(configureSupportingDocuments)
    return this.apiClient.put({
      path: `${departmentId}/configureSupportingDocuments`,
      body,
    })
  }

  overviewByCourtSystem({
    courtSystemId,
    isArchived,
  }: {
    courtSystemId: Uuid
    isArchived?: boolean
  }): ApiResult<DepartmentSummary[]> {
    const request: DepartmentOverviewRequest = {
      courtSystemId,
      isArchived,
    }
    const serializedParams = serializeHttpParams(request)
    return this.apiClient.get({ path: 'overview', params: serializedParams })
  }
}
