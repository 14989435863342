import { NgClass } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Colors } from '../design-system'

export enum LoaderSize {
  Regular = 'regular',
  Large = 'large',
}

@Component({
  standalone: true,
  selector: 'ftr-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() isLoading: boolean
  @Input() loaderText = 'Loading...'
  @Input() fullPage = false
  @Input() iconOnly = false
  @Input() inline = false
  @Input() progress: number
  @Input() color: Colors = Colors.Primary
  // Only affects if is inline
  @Input() withSpacer = true
  // Always large for full page loaders
  @Input() size = LoaderSize.Regular

  protected dashOffset = Math.PI * 31 + '%'
  protected readonly loaderSize = LoaderSize

  protected isDeterminate(): boolean {
    return this.progress !== undefined
  }

  getDashArray(progress: number): string {
    return `${progress * Math.PI}% ${(100 - progress) * Math.PI}%`
  }
}
