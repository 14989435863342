@if (isLoading) {
  <div
    class="loader"
    [class.loader--full-page]="fullPage"
    [class.loader--icon]="iconOnly"
    [class.loader--inline]="inline"
    [class.loader--inline--with-spacer]="inline && withSpacer"
    [class.loader--determinate]="isDeterminate()"
    [class.loader--large]="size === loaderSize.Large"
    role="progressbar"
  >
    <svg class="loader__spinner" viewBox="0 0 66 66">
      <circle
        class="loader__path"
        [style.stroke-dasharray]="isDeterminate() ? getDashArray(progress) : undefined"
        [style.stroke-dashoffset]="isDeterminate() ? dashOffset : undefined"
        fill="none"
        [attr.stroke]="color"
        stroke-width="6"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
    @if (!iconOnly) {
      <span class="loader__text">{{ loaderText }}</span>
    }
  </div>
}
