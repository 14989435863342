import { Injectable } from '@angular/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { featureFlag } from '@ftr/ui-feature-flags'
import { Observable } from 'rxjs'
import { ExternalUserNavItemsProvider } from './external/external-user-nav-items.provider'
import { InternalUserNavItemsProvider } from './internal/internal-user-nav-items.provider'
import { SideNavGroup, SideNavItem } from './side-nav-item'

@Injectable({
  providedIn: 'root',
})
export class SideNavItemsProvider {
  constructor(
    private readonly internalUserNavItemsProvider: InternalUserNavItemsProvider,
    private readonly externalUserNavItemsProvider: ExternalUserNavItemsProvider,
  ) {}

  // CO-3513: Remove flag
  private readonly featureDepartmentActivityPagesEnabled = featureFlag('feature-department-activity-pages')

  provide(courtSystemId: Uuid | undefined): Observable<{ navGroups: SideNavGroup[]; footerItems: SideNavItem[] }> {
    return courtSystemId
      ? this.internalUserNavItemsProvider.provide(courtSystemId, this.featureDepartmentActivityPagesEnabled())
      : this.externalUserNavItemsProvider.provide()
  }
}
