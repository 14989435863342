<span
  class="badge badge--color-{{ color }} badge--{{ modifier }} badge--{{ widthModifier }}"
  [class.badge--compact]="compact"
  [style]="{ maxWidth: maxWidth }"
>
  @if (icon) {
    <ftr-icon [icon]="icon" [size]="'Text'"></ftr-icon>
  }
  @if (text) {
    <span class="badge__text" [class.badge__text--compact]="compact">{{ text }}</span>
  }
</span>
