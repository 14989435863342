import { Component, Input, OnInit } from '@angular/core'
import { Icon, IconComponent } from '../icon'

export enum BadgeColors {
  MediumGrey = 'medium-grey',
  LightGrey = 'light-grey',
  Primary = 'primary',
  Blueberry = 'blueberry',
  Apple = 'apple',
  Aqua = 'aqua',
  Lemon = 'lemon',
  Mandarin = 'mandarin',
  Watermelon = 'watermelon',
  Brand = 'brand',
  Grape = 'grape',
  Secondary = 'secondary',
}

export type BadgeColor = `${BadgeColors}`

export interface BadgeConfig {
  color: BadgeColor
  icon: Icon
  text: string
}

@Component({
  standalone: true,
  imports: [IconComponent],
  selector: 'ftr-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css'],
})
export class BadgeComponent implements OnInit {
  @Input() color: BadgeColor | BadgeColors = BadgeColors.MediumGrey
  @Input() text?: string | undefined
  @Input() icon?: Icon | undefined
  @Input() maxWidth?: string
  /* When true, use a smaller form factor (Smaller font, auto height) */
  @Input() compact = false

  modifier: string
  widthModifier: string

  ngOnInit(): void {
    this.modifier = this.getModifier()
    this.widthModifier = this.maxWidth ? 'truncated-width' : 'content-width'
  }

  private getModifier(): string {
    if (this.text !== undefined && this.icon !== undefined) {
      return 'with-text-and-icon'
    }

    if (this.text !== undefined) {
      return 'only-text'
    }

    if (this.icon !== undefined) {
      return 'only-icon'
    }

    return 'empty'
  }
}
