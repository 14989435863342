import { AudioSegmentPlaybackData } from '@ftr/contracts/api/audio-segment'
import { RecordingWithSegments } from '@ftr/contracts/read'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { Uuid } from '@ftr/contracts/type/shared'

/**
 * `recordingId` is optional because audio segments for audio orders don't have one.
 * We could derive it from the recording segments, but it's important we don't.
 * If we did, we would attempt to join socket rooms which expect the audio segment to have a `recordingId`.
 * If we ever implement proper socket rooms for audio segments, we won't need a `recordingId` here at all.
 */
export interface RealTimePlaybackAudioSegmentKey {
  type: 'audio-segment'
  recordingId: Uuid | undefined
  audioSegmentId: Uuid
}

export interface RealTimePlaybackRecordingKey {
  type: 'recording'
  recordingId: Uuid
}

export interface RealTimePlaybackHearingKey {
  type: 'hearing'
  recordingId: Uuid
  hearingId: Uuid
}

export type RealTimePlaybackKey =
  | RealTimePlaybackAudioSegmentKey
  | RealTimePlaybackRecordingKey
  | RealTimePlaybackHearingKey

export function getRecordingPlaybackKey(recording: RecordingWithSegments): RealTimePlaybackRecordingKey {
  return { type: 'recording', recordingId: recording.id }
}

export function getAudioSegmentPlaybackKey(audioSegment: AudioSegmentPlaybackData): RealTimePlaybackAudioSegmentKey {
  return {
    type: 'audio-segment',
    audioSegmentId: audioSegment.id,
    recordingId: audioSegment.recordingId,
  }
}

export function getPlaybackId(key: RealTimePlaybackKey): Uuid {
  switch (key.type) {
    case 'recording':
      return key.recordingId
    case 'audio-segment':
      return key.audioSegmentId
    case 'hearing':
      return key.hearingId
    default:
      assertUnreachable(key)
  }
}
