import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SignupParams } from '@ftr/routing-paths'
import { LoggedInGuard } from '@ftr/ui-user'
import { SignupModule } from '~app/features/signup/signup.module'
import { ConfirmationSentPage } from './confirmation-sent.page'
import { SignupPage } from './signup.page'

@NgModule({
  imports: [
    SignupModule,
    RouterModule.forChild([
      {
        path: 'signup',
        component: SignupPage,
        canActivate: [LoggedInGuard],
        data: {
          title: 'Create an account',
        },
      },
      {
        path: 'confirmation-sent',
        canActivate: [LoggedInGuard],
        component: ConfirmationSentPage,
        data: {
          title: 'Confirmation Email Sent',
        },
      },
      {
        // We've move the verification (create password) into a lazy-loaded
        // submodule to bundle split the large password strength verifier dictionary
        path: `verification/:${SignupParams.Username}`,
        loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule),
      },
    ]),
  ],
  declarations: [SignupPage, ConfirmationSentPage],
  exports: [RouterModule],
})
export class SignupPagesModule {}
