<div
  class="input"
  [ngClass]="{
    'input--password': type === 'password',
    'input--text': type === 'text',
    'input--number': type === 'number',
    'input--prefix': !!prefix,
    'input--icon-prefix': !!iconPrefix,
    'input--highlight': (highlightError | async) && !hideValidationErrors,
    'input--hide-label': !!hideLabel,
    'input--disabled': ngFormControl.disabled,
    'input--flat': displayMode === 'FLAT',
  }"
>
  <input
    [id]="id"
    #input
    class="input__control"
    [ngClass]="{ 'input__control--has-value': hasValue }"
    [type]="type"
    [required]="required"
    [attr.name]="name"
    [attr.placeholder]="placeholder"
    [attr.pattern]="pattern"
    [attr.autocomplete]="autocomplete ? autocomplete : null"
    [attr.spellcheck]="spellcheck"
    [attr.tabindex]="tabIndex"
    [attr.data-notrack]="notrack"
    [formControl]="ngFormControl"
    [attr.aria-invalid]="ngFormControl.touched && !ngFormControl.valid"
    [attr.aria-label]="label"
    [maxlength]="maxLength ? maxLength : null"
    (keydown)="handleKeyDown($event)"
    (keyup)="handleKeyUp($event)"
    (focus)="focus.emit($event)"
  />
  @if (!!iconPrefix) {
    <ftr-icon [icon]="iconPrefix" [align]="'TextBottom'" [size]="'Regular'" class="input__icon-prefix"></ftr-icon>
  }
  @if (!!prefix) {
    <span class="input__prefix">{{ prefix }}</span>
  }
  <label class="input__label" [for]="id">
    {{ label }}
    @if (labelSuffix) {
      <span class="input__label-suffix"> - {{ labelSuffix }} </span>
    }
  </label>
  <div class="input__bar" [class.input__bar--disabled]="ngFormControl.disabled"></div>
  @if (!hideValidationErrors) {
    <span
      class="input__validation"
      ftrValidationErrorHint
      [label]="validationLabel || label"
      [ngFormControl]="ngFormControl"
      [controlTouched]="ngFormControl.touched"
      [submitAttempted]="submitAttempted"
    ></span>
  }
</div>
